import React, { Component } from 'react'
import about2Img from "../../../assets/images/spacialOffer.png"
import db from "./../../../firebaseConfig";
import { collection, query, onSnapshot} from 'firebase/firestore';
import OwlCarousel from 'react-owl-carousel'
import {Link} from "react-router-dom";
import AirlinesData from './../../../airlines.json';

export default class Aboutus extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            data: []
        };
    }
    componentDidMount() {
        const q = query(collection(db, 'FaresData'))
        onSnapshot(q, (querySnapshot) => {
            querySnapshot.docs.forEach(doc => {
                this.setState({data: JSON.parse(doc.data().Data)});
    
            })
        })
    }
    
    render() {
        const destinationsOptions = {
            stagePadding: 1,
            items: 3,
            loop: true,
            margin:20,
            smartSpeed: 1500,
            autoplay: true,
            dots: false,
            nav: true,
            navText : ["<i class='bx bx-chevron-left' ></i>","<i class='bx bx-chevron-right'></i>"],
            responsive:{
                0:{
                    items:1,
                    nav:false,
                    dots : false
                },
                600:{
                    items:2,
                    nav:false,
                    dost : false,
                },
                1000:{
                    items:3,
                    nav:true,
                    loop:true
                }
            }
        };
        let countryList = this.state.data.filter((v,i,a)=>a.findIndex(v2=>(v2.country === v.country))===i);
        console.log(countryList);
        return (
            <>
                <div className="about-wrapper mt-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="section-head pb-40">
                                    <h5>About Us</h5>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="about-wrapper-right">
                                    <h5>About Fast Flight</h5>
                                    <p>Since 1985 Eden International has been serving customers by booking flights and hotels to multiple destinations around the world. Initiated in United Kingdom by a team of three people who worked to provide flights tickets, the team strived to bring continuous improvements and innovations in their services. Eden International has excelled from providing flights to the Middle East and South Asia to delivering flights and holidays to destinations all around the world. In 2012 its head office moved from Oxford Street to Croydon and it now comprises of a team of over 50 qualified, dedicated, and skilled individuals who aim to give their customers memories to last a lifetime.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                <div className="destinations-area">
               <div className="container">
                            <div className="row">

                                 <div className="col-lg-3 col-md-3">
                                    <div className="package-slider-wrap">
                                        <img src={about2Img} alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-9">
                                    {this.state.data.length !== 0 ? <OwlCarousel className="row owl-carousel destinations-1"  {...destinationsOptions}>
                                        {countryList.map(item => {
                                            // console.log(item.arivalCity)
                                                    return (
                                                        <div className="package-card">
                                                            <div className="package-thumb">
                                                                <Link to={`${process.env.PUBLIC_URL}/destination-detail/${item.country}/${item.arivalCity}/${item.fares}`}>
                                                                    <img src={`/assets/images/${item.arivalCity}.png`} alt="" className="img-fluid" />
                                                                </Link>
                                                            </div>
                                                            <div className="package-details">
                                                                <div className="package-info">
                                                                    <Link to={`${process.env.PUBLIC_URL}/destination-detail/${item.country}/${item.arivalCity}/${item.fares}`}>
                                                                    <h5><span style={{fotSize: '12px'}}>From</span><span style={{fotSize: '30px', color: '#FF7F47' }}> £{item.fares}</span>/Per Person
                                            <img style={{ height: '50px' }} src={AirlinesData.filter(d => d.AirlineCode === item.airlineCode)[0].airlineLogo} alt="" />
                                            </h5>
                                             </Link>
                                          
                                        </div>
                                                                <h3><i className="flaticon-arrival" />
                                                                    <Link onClick={() => this.props.currentCountry(item.country)} to={`${process.env.PUBLIC_URL}/destination-detail/${item.country}/${item.arivalCity}/${item.fares}`} style={{fontSize: '15px'}}>{item.arivalCity}</Link>
                                                                </h3>
                                                                <div className="col-lg-6" style={{color: '#FF7F47', fontWeight: '700'}}>
                                                <a href="tel:02080904261"><input  style={{borderRadius: '5px', border: 'none'}} type="submit" value='Call Us: 02080904261' defaultValue="Book Now" /></a>
                                                </div> </div>
                                                        </div>
                                                    )
                                        })}
                                    </OwlCarousel> : null}
                                </div>
                            </div>
                            </div>
                            </div>
                </React.Fragment>
            </>
        )
    }
}
